// 创建一个需要混入的对象
import { delAdScheduleUpdateBudgetTask, delAdScheduleOpenTask } from '@/api/adManagement.js';
import setTime from './setTime.vue';
export const setTimeJS = {
  data() {
    return {
      setTimeIds: [],
    };
  },
  mounted() {},
  components: {
    setTime,
  },
  watch: {},
  methods: {
    // 取消定时
    cancelSet(type, row, scopeindex, index) {
      let msg = '';
      let request = null;
      if (type === 'task') {
        msg = '确定取消定时开启任务吗？';
        request = delAdScheduleOpenTask;
      } else {
        msg = '确定取消定时开启预算吗？';
        request = delAdScheduleUpdateBudgetTask;
      }
      this.$confirm(msg, '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data = {
            id: row.id,
          };
          request(JSON.stringify(data)).then((res) => {
            this.$message.success('取消成功！');
            row.open_schedule.splice(index, 1);
            this.$set(this.tableData, scopeindex, row);
          });
        })
        .catch(() => {});
    },
    // 设置定时时间
    settingComplete(form, type, id, budgetAmount) {
      let setTimeIds = [];
      if (type == 2) {
        setTimeIds.push(id);
      } else {
        setTimeIds = this.setTimeIds;
      }
      this.tableData.map((item, index) => {
        if (setTimeIds.indexOf(item.id) > -1) {
          let openSchedule = item.open_schedule || [];
          if (openSchedule.length > 0) {
            openSchedule.forEach((_item, _index) => {
              if (_item.type == type) {
                openSchedule.splice(_index, 1);
              }
            });
          }
          openSchedule.push({
            type: type,
            timeStr: form.dateVal, //+ this.adScheduleOpenTaskType.filter(v => v.name === form.taskType)[0].desc,
            budget: budgetAmount ? budgetAmount : null,
          });
          item.open_schedule = openSchedule;
          this.$set(this.tableData, index, item);
        }
      });
    },
    // 定时开启
    setTimeHandle() {
      this.setTimeIds = [];
      this.selectedRowScopes.forEach((item) => {
        if (item.status === 'PAUSED') {
          this.setTimeIds.push(item.id);
        }
      });
      if (this.setTimeIds.length == this.selectedRowScopes.length) {
        this.$refs.setTimeVue.show();
      } else {
        this.$message.warning('包含投放中的系列 | 组');
      }
    },
  },
};
